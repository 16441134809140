import React from "react";
import MyCard from "./MyCard";
import "../css/public.css";
import { CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import ImageIcon from "@mui/icons-material/Image";
import CompressIcon from "@mui/icons-material/Compress";
import SecurityIcon from "@mui/icons-material/Security";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const cardContentStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const iconStyle = {
  width: "5vw",
  height: "5vh",
  color: "red",
};

const Home = () => {
  return (
    <MyCard>
      <Link className="custom-link" to={"/imageReducer"}>
        <CardContent sx={cardContentStyle}>
          <FolderZipIcon sx={iconStyle} />
          <Typography gutterBottom variant="h5" component="div">
            Reduce Image
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "justify" }}
          >
            Reduce your image size by giving width and height, You can also
            convert your image to jpg, webp, png format.
          </Typography>
        </CardContent>
      </Link>
      <Link className="custom-link" to={"/imageToPdf"}>
        <CardContent sx={cardContentStyle}>
          <PictureAsPdfIcon sx={iconStyle} />
          <Typography gutterBottom variant="h5" component="div">
            Image To Pdf
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "justify" }}
          >
            Upload any image and convert it to pdf. Get best quality pdf.
          </Typography>
        </CardContent>
      </Link>
      <Link className="custom-link" to={"/pdfToImage"}>
        <CardContent sx={cardContentStyle}>
          <ImageIcon sx={iconStyle} />
          <Typography gutterBottom variant="h5" component="div">
            Pdf To Image
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "justify" }}
          >
            Upload any pdf and convert it to image.If your pdf has multiple
            pages than it will auto split and will downloaded in saparate image
            format.
          </Typography>
        </CardContent>
      </Link>

      <Link className="custom-link" to={"/mergePdf"}>
        <CardContent sx={cardContentStyle}>
          <CompressIcon sx={iconStyle} />
          <Typography gutterBottom variant="h5" component="div">
            Merge Pdf
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "justify" }}
          >
            Upload multiple pdfs and merge all pdfs. You can also reorder you
            pdfs after uploading.
          </Typography>
        </CardContent>
      </Link>
      <Link className="custom-link" to={"/pdfSplitter"}>
        <CardContent sx={cardContentStyle}>
          <SplitscreenIcon sx={iconStyle} />
          <Typography gutterBottom variant="h5" component="div">
            Split Pdf
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "justify" }}
          >
            Saperate all you pdfs in easy way with our handy tool.
          </Typography>
        </CardContent>
      </Link>
      <Link className="custom-link" to={"/rotatePdf"}>
        <CardContent sx={cardContentStyle}>
          <ChangeCircleIcon sx={iconStyle} />
          <Typography gutterBottom variant="h5" component="div">
            Rotate Pdf
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "justify" }}
          >
            Rotate your pdf in any direction and use for your own.
          </Typography>
        </CardContent>
      </Link>
      {/* <Link className="custom-link" to={"/watermarkPdf"}>
        <CardContent sx={cardContentStyle}>
          <BorderColorIcon sx={iconStyle} />
          <Typography gutterBottom variant="h5" component="div">
            Watermark PDF
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "justify" }}
          >
            Watermark your pdf in your own handyway so that you can easily apply
            your own branding.
          </Typography>
        </CardContent>
      </Link> */}

      {/* <Link className="custom-link" to={"/pdfProtector"}>
        <CardContent sx={cardContentStyle}>
          <SecurityIcon sx={iconStyle} />
          <Typography gutterBottom variant="h5" component="div">
            Protect Pdf
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "justify" }}
          >
            Add password to your pdf and secure it in easy way.
          </Typography>
        </CardContent>
      </Link> */}

      {/* <Link className="custom-link" to={"/unlockPdf"}>
        <CardContent sx={cardContentStyle}>
          <RemoveModeratorIcon sx={iconStyle} />
          <Typography gutterBottom variant="h5" component="div">
            Unlock Pdf
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "justify" }}
          >
            Upload any password protected pdf and remove password from pdf.
          </Typography>
        </CardContent>
      </Link> */}
      {/* <Link className="custom-link" to={"/PDFCompressor"}>
        <CardContent sx={cardContentStyle}>
          <CompressIcon sx={iconStyle} />
          <Typography gutterBottom variant="h5" component="div">
            Pdf Compressor
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "justify" }}
          >
            Upload your pdf and compress your pdf in your way.
          </Typography>
        </CardContent>
      </Link> */}
    </MyCard>
  );
};

export default Home;
