import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reduxImg: "",
};

export const imageToPdfSlice = createSlice({
  name: "reduxPdfImage",
  initialState,
  reducers: {
    setReduxImage: (state, action) => {
      return {
        ...state,
        reduxImg: action.payload,
      };
    },
  },
});

export const { setReduxImage } = imageToPdfSlice.actions;

export default imageToPdfSlice.reducer;
