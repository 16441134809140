import React, { useRef, useState } from "react";
import { PDFDocument } from "pdf-lib";
import {
  Button,
  Container,
  Typography,
  Box,
  Grid,
  Link,
  CardContent,
  Card,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PdfComp from "./PdfComp";

import CancelIcon from "@mui/icons-material/Cancel";
import { toast, ToastContainer } from "react-toastify";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const PdfSplitter = () => {
  const [splitPdfUrls, setSplitPdfUrls] = useState([]);
  const fileInputRef = useRef(null);

  const theme = useTheme();

  const truncatedText = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    height: "40px",
    width: "200px",
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
  };

  const splitPdf = async (e) => {
    debugger;

    const files = e.target.files[0];
    if (files) {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(files);

      fileReader.onload = async (e) => {
        try {
          debugger;
          const pdfBytes = e.target.result;
          const pdfDoc = await PDFDocument.load(pdfBytes);
          const totalPages = pdfDoc.getPageCount();

          const pdfUrls = [];

          for (let i = 0; i < totalPages; i++) {
            const newPdfDoc = await PDFDocument.create();
            const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [i]);
            newPdfDoc.addPage(copiedPage);

            const newPdfBytes = await newPdfDoc.save();
            const blob = new Blob([newPdfBytes], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);

            pdfUrls.push(url);
          }
          debugger;
          setSplitPdfUrls(pdfUrls);
        } catch (error) {
          if (error.message.includes("encrypted")) {
            // PDF is password-protected
            toast.error("The PDF is password-protected.");
            // Handle password-protected PDF case
          } else {
            // Handle other errors
            toast.error("Error rotating PDF:", error);
          }
        }
      };
    }
    fileInputRef.current.value = null;
  };

  const downloadAll = () => {
    splitPdfUrls.forEach((imageDataUrl, index) => {
      const a = document.createElement("a");
      a.href = imageDataUrl;
      a.download = `pdf_${index + 1}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  function handleDeleteTask(id) {
    const arr = splitPdfUrls.filter((task, index) => index !== id);
    if (arr.length === 0) {
      fileInputRef.current.value = null;
      setSplitPdfUrls([]);
    } else {
      setSplitPdfUrls(arr);
    }
  }
  return (
    <Container>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          backgroundColor: "#7acc91",
          borderRadius: "5px",
          textAlign: "Center",
        }}
      >
        Split PDF Into Separate Pages
      </Typography>
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            style={truncatedText}
          >
            {splitPdfUrls.length === 0 ? "Upload pdf" : "Reupload pdf"}
            <VisuallyHiddenInput
              ref={fileInputRef}
              type="file"
              accept="application/pdf"
              onChange={splitPdf}
              fullWidth
            />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={downloadAll}
            style={truncatedText}
            disabled={splitPdfUrls.length === 0}
          >
            Download All
          </Button>
        </Box>

        {splitPdfUrls.length > 0 && (
          <Typography
            variant="h5"
            sx={{
              backgroundColor: "#7acc91",
              borderRadius: "5px",
              marginTop: "10px",
            }}
          >
            Click any PDF to download or click DOWNLOAD ALL button.
          </Typography>
        )}
      </Box>

      {splitPdfUrls.length > 0 && (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {splitPdfUrls.map((url, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  paddingBottom: "30px",
                }}
              >
                <CardContent sx={{ position: "relative" }}>
                  <CancelIcon
                    sx={{
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                      zIndex: 1,
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => handleDeleteTask(index)}
                  />
                  <Link
                    href={url}
                    download={`split-page-${index + 1}.pdf`}
                    underline="none"
                  >
                    <PdfComp docUrl={url} />
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      <ToastContainer position="top-right" autoClose={2000} />
    </Container>
  );
};

export default PdfSplitter;
