import FileSaver from "file-saver";
import Resizer from "react-image-file-resizer";

export const resizeFileInWidthIntoHeight = (file, width, height, format) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      format,
      89,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

export async function resizeImage(image, width, height) {
  // Create a canvas element.
  debugger;
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, width, height);
  debugger;
  return await canvas.toBlob("image/jpeg");
}

export const resizeInFileSize = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const resizeInPercentage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

export async function downloadBlob(blob, filename, format) {
  debugger;
  const blobImage = blob;
  const fileName = `imageReduce.${format}`;
  FileSaver.saveAs(blobImage, fileName);
  debugger;
  // const href = URL.createObjectURL(blobImage);
  // const anchorElement = document.createElement('a');
  // debugger
  // anchorElement.href = href;
  // anchorElement.download = filename;
  // document.body.appendChild(anchorElement);
  // anchorElement.click();
  // anchorElement.remove();
}
