import React, { useRef, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const PdfProtector = () => {
  const [file, setFile] = useState(null);
  const [password, setPassword] = useState("");
  const [protectedPdfUrl, setProtectedPdfUrl] = useState("");

  const fileInputRef = useRef(null);

  const theme = useTheme();
  const truncatedText = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    height: "40px",
    width: "200px",
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
  };

  const handleFileChange = (e) => {
    debugger;
    const file = e.target.files[0];

    if (!file || file.type !== "application/pdf") {
      toast.error("Please select a PDF file");
      return;
    }
    setFile(file);
    fileInputRef.current.value = null;
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();
    if (!file || !password) {
      alert("Please upload a PDF file and set a password.");
      return;
    }

    const formData = new FormData();
    formData.append("pdf", file);
    formData.append("password", password);
    debugger;
    try {
      const response = await axios.post(
        "http://localhost:8000/api/v1/pdf/protect-pdf",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob", // important to handle binary data
        }
      );
      debugger;
      const url = URL.createObjectURL(response.data);
      setProtectedPdfUrl(url);
    } catch (error) {
      console.error("There was an error protecting the PDF:", error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid md={12} xs={12} sm={12} lg={12} item>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            backgroundColor: "#7acc91",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          Protect Your PDF
        </Typography>
      </Grid>
      <Grid
        md={12}
        xs={12}
        sm={12}
        lg={12}
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Box>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            style={truncatedText}
          >
            {!file ? "Upload Pdf" : "Reupload Pdf"}
            <VisuallyHiddenInput
              accept="application/pdf"
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </Button>
        </Box>
        <Box>
          <Typography variant="h6">{file ? file.name : ""}</Typography>
        </Box>
        <Box>
          <TextField
            value={password}
            variant="outlined"
            id="outlined-password-input"
            label="Password"
            type="password"
            required
            onChange={handlePasswordChange}
            size="small"
          />
        </Box>

        <Button
          variant="contained"
          style={truncatedText}
          type="submit"
          onClick={handleSubmit}
        >
          Protect PDF
        </Button>
      </Grid>
      <Grid
        md={12}
        xs={12}
        sm={12}
        lg={12}
        item
        sx={{
          display: "flex",

          gap: "10px",
          justifyContent: "center",
        }}
      >
        {protectedPdfUrl && (
          <Box>
            <h3>Your protected PDF is ready:</h3>
            <a href={protectedPdfUrl} download="protected.pdf">
              Download Protected PDF
            </a>
          </Box>
        )}
      </Grid>
      <ToastContainer position="top-right" autoClose={2000} />
    </Grid>
  );
};

export default PdfProtector;
