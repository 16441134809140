import React, { useRef, useState } from "react";
import { degrees, PDFDocument } from "pdf-lib";
import * as pdfjsLib from "pdfjs-dist";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Button, Typography, Grid, CardContent, Card } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { toast, ToastContainer } from "react-toastify";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import PdfComp from "./PdfComp";
pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
function PdfRotator() {
  const [pdfBytes, setPdfBytes] = useState(null);
  const [rotatedPdf, setRotatedPdf] = useState(null);

  const [pdfUrl, setPdfUrl] = useState(null); // For displaying PDF
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const truncatedText = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    height: "40px",
    width: "200px",
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
  };

  // Handle PDF upload
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        debugger;

        const bytes = new Uint8Array(reader.result);
        setPdfBytes(bytes);
        setPdfUrl(URL.createObjectURL(file)); // Set URL to display PDF
        setRotatedPdf(null);
      };
      reader.readAsArrayBuffer(file);
    }
    fileInputRef.current.value = null;
  };

  // Rotate the PDF pages and update the displayed PDF
  const rotatePdf = async () => {
    debugger;
    if (pdfBytes) {
      try {
        // Attempt to load the PDF
        const pdfDoc = await PDFDocument.load(pdfBytes);

        // Check if PDF requires a password (it will throw an error if it does)
        const pages = pdfDoc.getPages();

        // Rotate all pages by 90 degrees
        pages.forEach((page) => {
          let currentRotation = page.getRotation().angle; // Get the current angle
          const newRotation = (currentRotation + 90) % 360; // Ensure it stays within 0, 90, 180, 270
          page.setRotation(degrees(newRotation)); // Apply the new valid rotation
        });

        const rotatedPdfBytes = await pdfDoc.save();
        setRotatedPdf(rotatedPdfBytes);
        setPdfBytes(rotatedPdfBytes);
        setPdfUrl(
          URL.createObjectURL(
            new Blob([rotatedPdfBytes], { type: "application/pdf" })
          )
        ); // Display rotated PDF
      } catch (error) {
        if (error.message.includes("encrypted")) {
          // PDF is password-protected
          toast.error("The PDF is password-protected.");
          // Handle password-protected PDF case
        } else {
          // Handle other errors
          toast.error("Error rotating PDF:", error);
        }
      }
    }
  };

  // Download the rotated PDF
  const downloadRotatedPdf = () => {
    if (rotatedPdf) {
      const blob = new Blob([rotatedPdf], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "rotated.pdf";
      link.click();
    }
  };

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid md={12} xs={12} sm={12} lg={12} item>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            backgroundColor: "#7acc91",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          Rotate and View PDF
        </Typography>
      </Grid>

      <Grid item md={12} lg={12} sx={{ textAlign: "center" }}>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          style={truncatedText}
        >
          {!pdfUrl ? "Upload Pdf" : "Reupload Pdf"}
          <VisuallyHiddenInput
            accept="application/pdf"
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
          />
        </Button>
      </Grid>

      <Grid
        sx={{ marginY: 1, display: "flex", justifyContent: "Center" }}
        md={12}
        lg={12}
        container
        spacing={2}
      >
        <Grid item>
          <Button
            variant="contained"
            style={truncatedText}
            startIcon={<RotateRightIcon />}
            onClick={rotatePdf}
            disabled={!pdfBytes}
          >
            Rotate PDF
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            style={truncatedText}
            onClick={downloadRotatedPdf}
            disabled={!rotatedPdf}
          >
            Download Rotated PDF
          </Button>
        </Grid>
      </Grid>

      {pdfUrl && (
        <Grid item sx={{ textAlign: "Center" }}>
          <Typography variant="h6">PDF Preview:</Typography>
          <Card
            sx={{
              paddingBottom: "30px",
            }}
          >
            <CardContent>
              <PdfComp docUrl={pdfUrl} />
            </CardContent>
          </Card>
        </Grid>
      )}
      <ToastContainer position="top-right" autoClose={2000} />
    </Grid>
  );
}

export default PdfRotator;
