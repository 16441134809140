import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  themeColor : "light"
}

export const setColorOfUi = createSlice({
  name: 'uiColor',
  initialState,
  reducers: {
   
    setUicolor : (state, action) => {
        
        return {
            ...state,
            themeColor : action.payload
        }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUicolor } = setColorOfUi.actions

export default setColorOfUi.reducer