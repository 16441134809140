import React, { useState } from "react";
import { jsPDF } from "jspdf";

function PDFCompressor() {
  const [pdfFile, setPdfFile] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setPdfFile(file);
    } else {
      alert("Please upload a valid PDF file");
    }
  };

  const handleCompressPDF = () => {
    if (!pdfFile) {
      alert("Please upload a PDF file first");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const pdfData = e.target.result;

      // Create a new jsPDF instance
      const doc = new jsPDF();

      // Add the uploaded PDF content to jsPDF
      // In real use, you'd likely need to parse and re-render each page
      doc.addFileToVFS("uploaded.pdf", pdfData);
      doc.addFont("uploaded.pdf", "uploaded", "normal");
      doc.text("PDF compressed", 10, 10); // Placeholder, replace with real content handling

      // Compress the PDF
      const compressedPDF = doc.output("blob", { compress: true });

      // Create a download link for the compressed PDF
      const link = document.createElement("a");
      link.href = URL.createObjectURL(compressedPDF);
      link.download = "compressed.pdf";
      link.click();
    };
    reader.readAsArrayBuffer(pdfFile);
  };
  return (
    <div>
      <input type="file" accept="application/pdf" onChange={handleFileUpload} />
      <button onClick={handleCompressPDF}>Compress and Download PDF</button>
    </div>
  );
}

export default PDFCompressor;
