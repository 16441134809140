import { useState } from "react";
import * as pdfjsLib from "pdfjs-dist";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast, ToastContainer } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import PdfComp from "./PdfComp";

// pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.mjs";
pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const PdfToImage = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfPreview, setPdfPreview] = useState(null);
  const [images, setImages] = useState([]);
  const theme = useTheme();

  const truncatedText = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    height: "40px",
    width: "200px",
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file || file.type !== "application/pdf") {
      toast.error("Please select a PDF file");
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = function (e) {
      const arrayBuffer = e.target.result;

      pdfjsLib
        .getDocument({ data: arrayBuffer })
        .promise.then((pdf) => {
          // If successful, set the file and preview
          setPdfFile(file);

          // Create a URL object for the PDF file
          const fileUrl = URL.createObjectURL(file);

          // Set the PDF preview URL
          setPdfPreview(fileUrl);

          toast.success("PDF loaded successfully!");
        })
        .catch((error) => {
          console.error("Error loading PDF:", error);

          if (error.name === "PasswordException") {
            toast.error("This PDF is password-protected.");
          } else if (error.name === "InvalidPDFException") {
            toast.error("The PDF file is invalid or corrupted.");
          } else if (error.name === "MissingPDFException") {
            toast.error("The PDF file is missing or could not be found.");
          } else {
            toast.error("Failed to load PDF. Unknown error.");
          }
        });
    };

    fileReader.readAsArrayBuffer(file);
  };

  const handleConvert = async () => {
    if (!pdfFile) {
      toast.error("Please select a PDF file.");
      return;
    }

    try {
      // Read the file as an ArrayBuffer
      const fileReader = new FileReader();
      fileReader.onload = async function () {
        const arrayBuffer = this.result;

        // Get the PDF document
        const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
        const numPages = pdf.numPages;

        const imagePromises = [];
        for (let i = 1; i <= numPages; i++) {
          const page = await pdf.getPage(i);
          const viewport = page.getViewport({ scale: 1.5 }); // Adjust scale as needed
          const canvas = document.createElement("canvas");
          canvas.width = viewport.width;
          canvas.height = viewport.height;
          const context = canvas.getContext("2d");
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;

          const imageDataUrl = canvas.toDataURL("image/jpeg");
          imagePromises.push(imageDataUrl);
        }

        const convertedImages = await Promise.all(imagePromises);
        setImages(convertedImages);

        // Download each image directly
        convertedImages.forEach((imageDataUrl, index) => {
          const a = document.createElement("a");
          a.href = imageDataUrl;
          a.download = `image_${index + 1}.jpg`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      };

      fileReader.readAsArrayBuffer(pdfFile);
    } catch (err) {}
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid md={12} xs={12} sm={12} lg={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              backgroundColor: "#7acc91",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            Convert PDF To Image
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid
            item
            md={12}
            sm={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              style={truncatedText}
            >
              {pdfPreview ? "Reupload PDF" : "Upload Pdf"}
              <VisuallyHiddenInput
                type="file"
                accept="application/pdf"
                onChange={handleFileChange}
              />
            </Button>
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {pdfPreview && (
              <Card
                sx={{
                  paddingBottom: "30px",
                }}
              >
                <CardContent>
                  <PdfComp docUrl={pdfPreview} />
                </CardContent>
              </Card>
            )}
          </Grid>

          <Grid
            item
            md={12}
            sm={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={handleConvert}
              style={truncatedText}
            >
              Convert to Image
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ToastContainer position="top-right" autoClose={2000} />
    </>
  );
};

export default PdfToImage;
