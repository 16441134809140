import { Card, Grid } from "@mui/material";
import React from "react";

const myCardStyle = {
  width: "auto",
  height: "100%",
  display: "flex",
  flexGrow: 1,
};

const MyCard = (props) => {
  return (
    <Grid container spacing={1}>
      {props.children.map((item) => {
        return (
          <Grid item xs={6} sm={6} md={3}>
            <Card sx={myCardStyle}>{item}</Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MyCard;
