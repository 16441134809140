import { configureStore } from "@reduxjs/toolkit";
import imagereducer from "../redux/imageSlice";
import uiColorSclice from "./uiColorSclice";
import pdfReducer from "./imageToPdfSlice";

export const store = configureStore({
  reducer: {
    imagereduce: imagereducer,
    colorReducer: uiColorSclice,
    pdfReducer: pdfReducer,
  },
});
