import { Box } from "@mui/material";
import { memo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfComp = memo(function PdfComp(props) {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Box style={{ height: "100px" }}>
      <Document file={props.docUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page width={100} height={100} pageNumber={pageNumber} />
      </Document>
    </Box>
  );
});
export default PdfComp;
