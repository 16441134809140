import { Button, Grid, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast, ToastContainer } from "react-toastify";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import { setReduxImage } from "../redux/imageToPdfSlice";
import { useTheme } from "@mui/material/styles";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const myGridStyle = {
  display: "flex",
  justifyContent: "center",

  height: "60vh",
  "@media (max-width: 768px)": {
    height: "40vh",
  },
};

const ImageToPdf = () => {
  const [image, setImage] = useState("");
  const imageRef = useRef("");
  const { reduxImg } = useSelector((state) => state.pdfReducer);
  const dispatch = useDispatch();
  const theme = useTheme();

  const truncatedText = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    height: "40px",
    width: "200px",
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
  };
  const uploadImage = async (e) => {
    if (e.target.value.length) {
      const uploadFile = e.target.files[0];
      if (!uploadFile.type.startsWith("image/")) {
        toast.error("please select an image file");
        return;
      }

      let imgfile = URL.createObjectURL(uploadFile);
      setImage(imgfile);
      dispatch(setReduxImage(imgfile));
    }
  };

  const convertToPDF = () => {
    const input = imageRef.current;
    if (!input) return;

    html2canvas(input).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      // const { width: imgWidth, height: imgHeight } = await getImageSize(
      //   imgData
      // );
      const imgWidth = 210; // A4 width in mm
      // const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgHeight = 297;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight, "", "FAST");
      pdf.save("download.pdf");
    });
  };
  return (
    <>
      <Grid container spacing={2} style={myGridStyle}>
        <Grid md={12} xs={12} sm={12} lg={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              backgroundColor: "#7acc91",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            Convert Image To PDF
          </Typography>
        </Grid>
        {!reduxImg && (
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            style={truncatedText}
          >
            Upload Image
            <VisuallyHiddenInput
              type="file"
              accept="image/*"
              onChange={(e) => {
                uploadImage(e);
              }}
            />
          </Button>
        )}
        {reduxImg && (
          <>
            <img
              src={reduxImg}
              alt="Your Upload"
              ref={imageRef}
              style={{
                width: "80vw",
                height: "60vh",
                objectFit: "contain",
              }}
            />
            <Grid
              item
              width={"80vw"}
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={convertToPDF}
                style={truncatedText}
              >
                Convert to PDF
              </Button>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                style={truncatedText}
              >
                Reupload Image
                <VisuallyHiddenInput
                  accept="image/*"
                  type="file"
                  onChange={(e) => {
                    uploadImage(e);
                  }}
                />
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <ToastContainer position="top-right" autoClose={2000} />
    </>
  );
};

export default ImageToPdf;
