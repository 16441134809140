import React, { useRef, useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { Button, FormControlLabel, Input, TextField } from "@mui/material";
import * as pdfjsLib from "pdfjs-dist";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTheme } from "@mui/material/styles";
import { Box, FormGroup, Grid, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UnlockPdf = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [unprotectedPdf, setUnprotectedPdf] = useState(null);
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef(null);

  const theme = useTheme();
  const truncatedText = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    height: "40px",
    width: "200px",
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
  };

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
    fileInputRef.current.value = null;
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const resetForm = () => {
    setPdfFile(null);
    setUnprotectedPdf(null);
    setPassword("");
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();
    if (!pdfFile || !password) {
      toast.error("Please upload a PDF and enter a password.");
      return;
    }

    const formData = new FormData();
    formData.append("pdf", pdfFile);
    formData.append("password", password);

    setIsLoading(true);

    try {
      // Send POST request to unlock the PDF
      const response = await axios.post(
        "http://localhost:8000/api/v1/pdf/unlock-pdf",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob", // To handle binary data (PDF)
        }
      );

      // Create a blob link to download the unlocked PDF
      const url = URL.createObjectURL(response.data);
      setUnprotectedPdf(url);
    } catch (err) {
      toast.error(
        "Failed to unlock the PDF. Please check the password or try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid md={12} xs={12} sm={12} lg={12} item>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            backgroundColor: "#7acc91",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          Unlock PDF
        </Typography>
      </Grid>
      <Grid
        md={12}
        xs={12}
        sm={12}
        lg={12}
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Box>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            style={truncatedText}
          >
            {!pdfFile ? "Upload Pdf" : "Reupload Pdf"}
            <VisuallyHiddenInput
              accept="application/pdf"
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </Button>
        </Box>
        <Box>
          <Typography variant="h6">{pdfFile ? pdfFile.name : ""}</Typography>
        </Box>
        <Box>
          <TextField
            value={password}
            variant="outlined"
            id="outlined-password-input"
            label="Password"
            type="password"
            required
            onChange={handlePasswordChange}
            size="small"
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            style={truncatedText}
            type="submit"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Unlocking..." : "Unlock PDF"}
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            style={truncatedText}
            onClick={resetForm}
            disabled={isLoading}
          >
            Reset
          </Button>
        </Box>
      </Grid>
      <Grid
        md={12}
        xs={12}
        sm={12}
        lg={12}
        item
        sx={{
          display: "flex",

          gap: "10px",
          justifyContent: "center",
        }}
      >
        {unprotectedPdf && (
          <Box>
            <h3>Your unprotected PDF is ready:</h3>
            <a href={unprotectedPdf} download="unProtected.pdf">
              Click to download Unprotected PDF
            </a>
          </Box>
        )}
      </Grid>
      <ToastContainer position="top-right" autoClose={2000} />
    </Grid>
  );
};

export default UnlockPdf;
