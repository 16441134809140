import * as React from "react";
import "../css/public.css";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ContrastIcon from "@mui/icons-material/Contrast";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CropIcon from "@mui/icons-material/Crop";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { setImage, setImageColor, setImgAngle } from "../redux/imageSlice";
import logo from "../assets/logo.png";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import ImageIcon from "@mui/icons-material/Image";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { Grid } from "@mui/material";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import CompressIcon from "@mui/icons-material/Compress";
import { route } from "../routes/routes";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

const drawerWidth = 220;
const iconStyle = {
  color: "red",
};
function ResponsiveDrawer(props) {
  const { window, changeUiColorMode, themeColor } = props;
  const theme = useTheme();
  const { img, imgurl, imgAngle } = useSelector((state) => state.imagereduce);
  const navigate = useNavigate();
  const path = useLocation().pathname;

  let Location = path;

  // const uiCol = useSelector((state) => state.colorReducer);
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const rotateImage = (file, rotationCount, callback) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = img.height;
        canvas.height = img.width;

        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((rotationCount * Math.PI) / 180);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);

        const rotatedImg = canvas.toDataURL("image/jpeg");
        callback(rotatedImg);
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };
  const flipImage = async () => {
    debugger;
    let angel = imgAngle;
    angel += 90;

    rotateImage(imgurl, angel, async (rotateImage) => {
      debugger;
      dispatch(setImage(rotateImage));

      dispatch(setImgAngle(angel));
    });

    debugger;
  };

  const colorChange = () => {
    debugger;
    let imgColor = img.imgColorPercentage;
    if (imgColor === 100) {
      imgColor = 0;
    } else {
      imgColor = 100;
    }
    dispatch(setImageColor(imgColor));
  };

  // const uploadNewImage = () => {
  //   const input = document.createElement("input");
  //   input.type = "file";
  //   input.id = "upload";

  //   // Event listener to handle file selection
  //   input.addEventListener("change", function (event) {
  //     debugger;
  //     const uploadFile = event.target.files[0];
  //     if (!uploadFile.type.startsWith("image/")) {
  //       alert("please select an image file");
  //       return;
  //     }

  //     let imgfile = URL.createObjectURL(uploadFile);
  //     dispatch(setImage(imgfile));
  //     dispatch(setImgUrl(uploadFile));
  //     // Perform actions with the selected file here
  //   });

  //   // Append the input element to the body or any other DOM element
  //   document.body.appendChild(input);

  //   // Trigger the click event after the input has been added to the DOM
  //   input.click();
  // };

  const drawer = (
    <div>
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <img
          src={logo}
          alt="logo"
          width={60}
          height={60}
          title="imageReducerLogo"
        />
        Image Resizer
      </Toolbar>
      <Divider />
      <List>
        {[
          "Reduce Image",
          "Image to pdf",
          "Pdf to Image",
          "Merge PDF",
          "Split PDF",
          "Rotate PDF",
        ].map((text, index) => (
          <ListItem
            key={text}
            // style={{ color: theme.palette.primary.contrastText }}
            onClick={() => {
              if (text === "Reduce Image") {
                navigate("/imageReducer");
              }
              if (text === "Image to pdf") {
                navigate("/imageToPdf");
              }
              if (text === "Pdf to Image") {
                navigate("/pdfToImage");
              }
              if (text === "PDF Compressor") {
                navigate("/PDFCompressor");
              }
              if (text === "PDF To Word") {
                navigate("/PdfToWord");
              }
              if (text === "Merge PDF") {
                navigate("/mergePdf");
              }
              if (text === "Split PDF") {
                navigate("/pdfSplitter");
              }
              if (text === "Rotate PDF") {
                navigate("/rotatePdf");
              }

              handleDrawerToggle();
            }}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>
                {text === "Reduce Image" && (
                  <Link
                    style={{ color: theme.palette.icon.primary }}
                    to={"/imageReducer"}
                  >
                    <FolderZipIcon style={iconStyle} />
                  </Link>
                )}
                {text === "Image to pdf" && (
                  <Link
                    style={{ color: theme.palette.icon.primary }}
                    to={"/imageToPdf"}
                  >
                    <PictureAsPdfIcon style={iconStyle} />
                  </Link>
                )}
                {text === "Pdf to Image" && (
                  <Link
                    style={{ color: theme.palette.icon.primary }}
                    to={"/pdfToImage"}
                  >
                    <ImageIcon style={iconStyle} />
                  </Link>
                )}
                {text === "PDF Compressor" && (
                  <Link
                    style={{ color: theme.palette.icon.primary }}
                    to={"/PDFCompressor"}
                  >
                    <CompressIcon style={iconStyle} />
                  </Link>
                )}
                {text === "PDF To Word" && (
                  <Link
                    style={{ color: theme.palette.icon.primary }}
                    to={"/PDFCompressor"}
                  >
                    <CompressIcon style={iconStyle} />
                  </Link>
                )}
                {text === "Merge PDF" && (
                  <Link
                    style={{ color: theme.palette.icon.primary }}
                    to={"/mergePdf"}
                  >
                    <CompressIcon style={iconStyle} />
                  </Link>
                )}
                {text === "Split PDF" && (
                  <Link
                    style={{ color: theme.palette.icon.primary }}
                    to={"/pdfSplitter"}
                  >
                    <SplitscreenIcon style={iconStyle} />
                  </Link>
                )}
                {text === "Rotate PDF" && (
                  <Link
                    style={{ color: theme.palette.icon.primary }}
                    to={"/rotatePdf"}
                  >
                    <ChangeCircleIcon style={iconStyle} />
                  </Link>
                )}
                {text === "Crop Image" && <CropIcon />}
                {text === "Black/White" && <ContrastIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      {/* Navbar */}

      <Box>
        <CssBaseline />
        <AppBar
          sx={{
            width: { sm: `100%` },
            ml: { sm: `0px` },
            position: "static",
          }}
        >
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "25px",
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <IconButton
              color={theme.secondary}
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                display: { xs: "block", sm: "block", md: "block" },
                // "@media (max-width: 1465px)": {
                //   display: "block",
                // },
              }}
            >
              <MenuIcon style={{ color: theme.palette.icon.primary }} />
            </IconButton>
            <Typography
              display={"flex"}
              flexDirection={"row"}
              variant="h1"
              style={{
                textTransform: "capitalize",
                fontSize: "40px",
                fontWeight: "bold",
                backgroundImage: "conic-gradient(#553c9a, #ee4b2b, #00c2cb)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                webkitTextFillColor: "transparent",
              }}
            >
              <span>upload image or pdf and see the magic</span>
            </Typography>
            <DarkModeIcon
              style={{ color: theme.palette.icon.primary }}
              onClick={() => {
                if (themeColor === false) {
                  localStorage.setItem("themeColor", JSON.stringify(true));
                  changeUiColorMode((prev) => !prev);
                } else {
                  localStorage.setItem("themeColor", JSON.stringify(false));
                  changeUiColorMode((prev) => !prev);
                }
              }}
            />
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth, md: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "block", lg: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
              // "@media (max-width: 1465px)": {
              //   display: "none",
              // },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        ></Box>
      </Box>

      <Grid
        container
        spacing={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={10}>
          <Routes>
            {route
              .filter((item) => item.title === Location)
              .map((value) => {
                return (
                  <Route
                    key={value.title}
                    path={value.title}
                    element={<value.Component />}
                  />
                );
              })}
          </Routes>
        </Grid>
      </Grid>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */

  window: PropTypes.func,
};

export default ResponsiveDrawer;
