import { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { getImageSize } from "react-image-size";
import { useSelector, useDispatch } from "react-redux";
import {
  setImage,
  setImageFormat,
  setImageHeight,
  setImageWidth,
  setImgSize,
  setImgUrl,
} from "../redux/imageSlice";
import "react-toastify/dist/ReactToastify.css";
import "react-image-crop/dist/ReactCrop.css";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { downloadBlob, resizeFileInWidthIntoHeight } from "../helper/helper";
import { ToastContainer, toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const myDisplay = {
  display: "flex",
  alignItems: "center",
};
const myDisplay1 = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default function ImageReducer() {
  const [selectedValue, setSelectedValue] = useState("a");
  const [imageName, setImageName] = useState("");
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [format, setFormat] = useState("JPG");
  const [imageSize, setImageSize] = useState(0);
  const [imageSizeFormat, SetimageSizeFormat] = useState("kb");
  const theme = useTheme();
  // const deferredHeight = useDeferredValue(height);
  // const deferredWidth = useDeferredValue(width);
  const {
    img: image,
    imgHeight,
    imgWidth,
    imgSize,
    imgurl,
    imgFormat,
  } = useSelector((state) => state.imagereduce);

  const dispatch = useDispatch();

  const imageRef = useRef();

  const truncatedText = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    height: "40px",
    width: "200px",
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
  };

  const uploadImage = async (e) => {
    debugger;

    if (e.target.value.length) {
      debugger;
      const uploadFile = e.target.files[0];
      if (!uploadFile.type.startsWith("image/")) {
        toast.error("please select an image file");
        return;
      }

      let imgfile = URL.createObjectURL(uploadFile);

      const { width, height } = await getImageSize(imgfile);
      const res = await resizeFileInWidthIntoHeight(
        uploadFile,
        width,
        height,
        format
      );
      const uptoOneDigit = (res.size / 1024).toFixed(1);
      setImageSize(uptoOneDigit);
      dispatch(setImage(imgfile));
      dispatch(setImgUrl(uploadFile));
      setHeight(height);
      setWidth(width);
      dispatch(setImgSize(uptoOneDigit));
      dispatch(setImageHeight(height));
      dispatch(setImageWidth(width));
      setImageName(uploadFile);
      debugger;
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const formatChange = async (e) => {
    debugger;
    let val = e.target.value;
    setFormat(val);
    const im = imgurl;

    const res = await resizeFileInWidthIntoHeight(im, imgWidth, imgHeight, val);
    const uptoOneDigit = (res.size / 1024).toFixed(1);
    setImageSize(uptoOneDigit);
    dispatch(setImgSize(uptoOneDigit));
    dispatch(setImageFormat(val));
  };

  const resizeClick = async () => {
    debugger;
    if (imgurl) {
      const im = imgurl;
      const res = await resizeFileInWidthIntoHeight(im, width, height, format);

      debugger;
      downloadBlob(res, im, format);
      debugger;
    } else {
      toast.error("please upload image");
    }
  };

  const handleWidthChange = async (val) => {
    debugger;
    const im = imgurl;
    setWidth(val);
    dispatch(setImageWidth(val));
    if (val && val >= 1 && imgHeight) {
      const res = await resizeFileInWidthIntoHeight(im, val, imgHeight, format);
      const uptoOneDigit = (res.size / 1024).toFixed(1);
      setImageSize(uptoOneDigit);
      dispatch(setImgSize(uptoOneDigit));
    }
  };

  const handleHieghtChange = async (val) => {
    debugger;
    const im = imgurl;
    setHeight(val);
    dispatch(setImageHeight(val));
    if (val && val >= 1 && imgWidth) {
      const res = await resizeFileInWidthIntoHeight(im, imgWidth, val, format);
      const uptoOneDigit = (res.size / 1024).toFixed(1);
      setImageSize(uptoOneDigit);
      dispatch(setImgSize(uptoOneDigit));
    }
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid md={12} xs={12} sm={12} lg={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              backgroundColor: "#7acc91",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            Reduce your image size
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={1}>
            <Grid item xs={1} sm={1} md={1} lg={1} style={myDisplay1}>
              <Radio
                checked={selectedValue === "a"}
                onChange={handleChange}
                value="a"
                name="chooseSize"
              />
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={3} style={myDisplay}>
              <h2 style={{ fontSize: "18px" }}>Width X Height</h2>
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={3} style={myDisplay}>
              <TextField
                fullWidth
                onChange={(e) => {
                  if (e.target.value <= 3000) {
                    handleWidthChange(e.target.value);
                  }
                }}
                size={"small"}
                label="Width"
                value={imgWidth}
                type="number"
                inputProps={{ max: 3000 }}
                disabled={selectedValue !== "a"}
              />
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={3} style={myDisplay}>
              <TextField
                fullWidth
                onChange={(e) => {
                  if (e.target.value <= 3000) {
                    handleHieghtChange(e.target.value);
                  }
                }}
                size={"small"}
                label="Height"
                value={imgHeight}
                type="number"
                inputProps={{ max: 3000 }}
                disabled={selectedValue !== "a"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={1} sm={1} md={1} lg={1} style={myDisplay}>
              {/* <Radio
            checked={selectedValue === "a"}
            onChange={handleChange}
            value="a"
            name="chooseSize"
          /> */}
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={3} style={myDisplay}>
              <h3 style={{ fontSize: "18px" }}>File Size</h3>
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={3} style={myDisplay}>
              <TextField
                fullWidth
                value={imgSize}
                onChange={(e) => {
                  setImageSize(e.target.value);
                }}
                size={"small"}
                type="number"
                inputProps={{ min: 0 }}
                disabled={selectedValue !== "b"}
              />
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={3} style={myDisplay}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-standard-label">
                  Size
                </InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={imageSizeFormat}
                  onChange={(e) => {
                    SetimageSizeFormat(e.target.value);
                  }}
                  label="Age"
                  disabled={selectedValue !== "b"}
                  size="small"
                >
                  <MenuItem value={"kb"}>Kb</MenuItem>
                  <MenuItem value={"mb"}>Mb</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={1} sm={1} md={1} lg={1} style={myDisplay}>
              {/* <Grid xs={3} md={3}>
              <Radio
                checked={selectedValue === "c"}
                onChange={handleChange}
                value="c"
                name="chooseSize"
              />
            </Grid>

            <Grid xs={3} md={3}>
              <h5>Percentage</h5>
            </Grid> */}
              {/* <Grid xs={3} md={3}>
              <TextField
                size={"small"}
                type="number"
                inputProps={{ min: 0 }}
                disabled={selectedValue !== "c"}
              />
            </Grid>

            <Grid xs={3} md={3}>
              <h5>%</h5>
            </Grid> */}
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={3} style={myDisplay}>
              {/* <Grid xs={3} md={3}>
              <Radio
                checked={selectedValue === "c"}
                onChange={handleChange}
                value="c"
                name="chooseSize"
              />
            </Grid>

            <Grid xs={3} md={3}>
              <h5>Percentage</h5>
            </Grid> */}
              {/* <Grid xs={3} md={3}>
              <TextField
                size={"small"}
                type="number"
                inputProps={{ min: 0 }}
                disabled={selectedValue !== "c"}
              />
            </Grid>

            <Grid xs={3} md={3}>
              <h5>%</h5>
            </Grid> */}
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={3} style={myDisplay}>
              <FormControl>
                <InputLabel id="demo-simple-select-standard-label">
                  Format
                </InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={imgFormat}
                  onChange={(e) => {
                    formatChange(e);
                  }}
                  label="Age"
                  size="small"
                >
                  <MenuItem value={"JPG"}>JPG</MenuItem>
                  <MenuItem value={"PNG"}>PNG</MenuItem>
                  <MenuItem value={"WEBP"}>WEBP</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginTop: "0.3rem" }}>
            {/* <Grid item xs={false} sm={false} md={1} lg={1} style={myDisplay}></Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={4} style={myDisplay}>
              <Button
                fullWidth
                variant="contained"
                style={truncatedText}
                onClick={() => {
                  resizeClick();
                }}
              >
                Download
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} style={myDisplay}>
              <Button
                fullWidth
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                style={truncatedText}
              >
                {image ? "Reupload Image" : "Upload Image"}
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    uploadImage(e);
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {/* <ReactCrop
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(e) => {
            console.log(e);
            setCompletedCrop(e);
          }}
        > */}
          {image && (
            <img
              src={image}
              alt="your imgclick"
              ref={imageRef}
              style={{
                width: "62%",
                height: "205px",
                objectFit: "contain",
              }}
            />
          )}
          {/* </ReactCrop> */}
        </Grid>
      </Grid>
      <ToastContainer position="top-right" autoClose={2000} />
    </>
  );
}
