import { useEffect, useState } from "react";
import "./App.css";
import Main from "./pages/Main";
import { ThemeProvider, createTheme } from "@mui/material/styles";

function App() {
  const [color, changeColor] = useState(
    JSON.parse(localStorage.getItem("themeColor"))
  );

  const darkTheme = createTheme({
    palette: {
      mode: color ? "light" : "dark",
      primary: {
        main: color ? "#3e3e42" : "#ba000d", // Primary color based on the mode
        contrastText: color ? "#333333" : "#BBBBBB", // Text color that contrasts with primary color
      },
      text: {
        primary: color ? "#000000" : "#FFFFFF", // Primary text color
        secondary: color ? "#666666" : "#BBBBBB", // Secondary text color
      },
      background: {
        default: color ? "#FFFFFF" : "#121212", // Main background color
        paper: color ? "#F0F0F0" : "#1E1E1E", // Background for cards, etc.
      },
      icon: {
        primary: color ? "#000000" : "#FFFFFF", // Icon color
      },
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <Main changeUiColorMode={changeColor} themeColor={color} />
      </ThemeProvider>
    </div>
  );
}

export default App;
