import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  img: "",
  imgurl: "",
  imgAngle: 0,
  imgColorPercentage: 0,
  buttonVisible: true,
  imgHeight: 0,
  imgWidth: 0,
  imgSize: 0,
  imgFormat: "JPG",
};

export const setImageSclice = createSlice({
  name: "reduxImage",
  initialState,
  reducers: {
    setImgSize: (state, action) => {
      return {
        ...state,
        imgSize: action.payload,
      };
    },
    setImage: (state, action) => {
      return {
        ...state,
        img: action.payload,
      };
    },
    setImgUrl: (state, action) => {
      return {
        ...state,
        imgurl: action.payload,
      };
    },
    setImgAngle: (state, action) => {
      let val = action.payload;
      if (val === 360) {
        val = 0;
      }
      return {
        ...state,
        imgAngle: val,
      };
    },
    setImageColor: (state, action) => {
      return {
        ...state,
        imgColorPercentage: action.payload,
      };
    },
    setButtonVisible: (state, action) => {
      return {
        ...state,
        buttonVisible: action.payload,
      };
    },
    setImageHeight: (state, action) => {
      return {
        ...state,
        imgHeight: action.payload,
      };
    },
    setImageWidth: (state, action) => {
      return {
        ...state,
        imgWidth: action.payload,
      };
    },
    setImageFormat: (state, action) => {
      return {
        ...state,
        imgFormat: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setImage,
  setImgUrl,
  setImgAngle,
  setImageColor,
  setImageHeight,
  setImageWidth,
  setImgSize,
  setImageFormat,
} = setImageSclice.actions;

export default setImageSclice.reducer;
