import React, { useState, useRef, useLayoutEffect } from "react";
import { degrees, PDFDocument, rgb } from "pdf-lib";
import { Slider, Button, TextField } from "@mui/material";
import { Document, Page } from "react-pdf";

const WatermarkPDF = () => {
  const [pdfBytes, setPdfBytes] = useState(null); // Holds the original PDF bytes
  const [pdfUrl, setPdfUrl] = useState(null); // URL for PDF preview
  const [rotatedPdf, setRotatedPdf] = useState(null); // Holds the watermarked/rotated PDF
  const [zoomLevel, setZoomLevel] = useState(1); // Zoom level for watermark
  const [rotation, setRotation] = useState(0); // Watermark rotation
  const [opacity, setOpacity] = useState(1); // Watermark opacity
  const [watermarkText, setWatermarkText] = useState("Watermark"); // Watermark text
  const fileInputRef = useRef(null);

  useLayoutEffect(() => {
    const canvas = document.getElementById("canvas");
    const context = canvas.getContext("2d");
    context.font = "48px serif";
    context.fillText("Hello world", 10, 50);
  });

  // Handle file upload
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        const bytes = new Uint8Array(reader.result);
        setPdfBytes(bytes); // Set original PDF bytes
        setPdfUrl(URL.createObjectURL(file)); // Set URL to display the PDF
        setRotatedPdf(null); // Clear any previous watermarked PDF
      };
      reader.readAsArrayBuffer(file);
    }
    fileInputRef.current.value = null;
  };

  // Add watermark
  // const addWatermark = async () => {
  //   if (!pdfBytes) return;

  //   const pdfDoc = await PDFDocument.load(pdfBytes);
  //   const pages = pdfDoc.getPages();
  //   const firstPage = pages[0]; // Add watermark to first page (loop if needed)
  //   const { width, height } = firstPage.getSize();

  //   // Add the watermark
  //   firstPage.drawText(watermarkText, {
  //     x: width / 4,
  //     y: height / 2,
  //     size: 50 * zoomLevel,
  //     color: rgb(0, 0, 0),
  //     rotate: degrees((rotation * Math.PI) / 180), // Convert rotation to radians
  //     opacity, // Apply opacity
  //   });

  //   // Save the modified PDF
  //   const pdfBytesWithWatermark = await pdfDoc.save();
  //   const blob = new Blob([pdfBytesWithWatermark], { type: "application/pdf" });
  //   setRotatedPdf(URL.createObjectURL(blob)); // Set URL to display modified PDF
  // };

  const downloadPdf = () => {
    if (rotatedPdf) {
      const link = document.createElement("a");
      link.href = rotatedPdf;
      link.download = "watermarked.pdf";
      link.click();
    }
  };

  const addWatermark = () => {};

  return (
    <div>
      <h1>Add Watermark to PDF</h1>
      <input
        type="file"
        onChange={handleFileUpload}
        accept="application/pdf"
        ref={fileInputRef}
      />

      {/* Input for Watermark Text */}
      <TextField
        label="Watermark Text"
        value={watermarkText}
        onChange={(e) => setWatermarkText(e.target.value)}
        style={{ margin: "20px 0" }}
      />

      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={addWatermark}
          disabled={!pdfBytes}
        >
          Add Watermark
        </Button>
      </div>

      {/* Sliders for Zoom, Rotation, and Opacity */}
      <div style={{ marginTop: "20px" }}>
        <h3>Zoom</h3>
        <Slider
          value={zoomLevel}
          min={0.5}
          max={2}
          step={0.1}
          onChange={(e, value) => setZoomLevel(value)}
        />

        <h3>Rotate</h3>
        <Slider
          value={rotation}
          min={0}
          max={360}
          step={5}
          onChange={(e, value) => setRotation(value)}
        />

        <h3>Fade (Opacity)</h3>
        <Slider
          value={opacity}
          min={0}
          max={1}
          step={0.1}
          onChange={(e, value) => setOpacity(value)}
        />
      </div>

      {/* Display the original or watermarked PDF */}
      {pdfUrl && !rotatedPdf && (
        <div>
          <h3>Original PDF Preview:</h3>
          <Document file={pdfUrl}>
            <Page pageNumber={1} />
          </Document>
        </div>
      )}

      {rotatedPdf && (
        <div>
          <h3>Watermarked PDF Preview:</h3>
          <Document file={rotatedPdf}>
            <Page pageNumber={1} />
          </Document>

          <Button
            variant="contained"
            color="secondary"
            onClick={downloadPdf}
            style={{ marginTop: "20px" }}
          >
            Download PDF
          </Button>
        </div>
      )}
      <canvas
        id="canvas"
        width={window.innerWidth}
        height={window.innerHeight}
        // onMouseDown={handleMouseDown}
        // onMouseMove={handleMouseMove}
        // onMouseUp={handleMouseUp}
        style={{ position: "absolute", zIndex: 1 }}
      >
        canvas
      </canvas>
    </div>
  );
};

export default WatermarkPDF;
